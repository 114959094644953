import { useEffect, useState } from 'react'
import LeftMenu from '../../../component/Admin/AdminLeftMenu';
import UserDetail from '../../../component/Admin/MerchantKYCDetail';
import BlockUser from '../../../component/BlockUser';
import UnblockUser from '../../../component/UnblockUser';
import DatePicker from "react-datepicker";
import Modal from 'react-bootstrap/Modal';
import "react-datepicker/dist/react-datepicker.css";
import identityService from '../../../services/identityService';
import { useDispatch, useSelector } from "react-redux"
import { getUserKYCDetails } from '../../../redux/features/KYC/kycAction';
import { AppDispatch } from 'redux/store';
import ReactPaginate from 'react-paginate';
import { useDebounce } from 'use-debounce';
import PopUp from '../../../component/PopUp';
import Skeleton from 'react-loading-skeleton'
import 'react-loading-skeleton/dist/skeleton.css'

const initialState: any = {
  page: 1,
  search: "",
  size: 10,
  status: ""
};


const UserManagement = () => {

  const [show, setShow] = useState(false);
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  const [show2, setShow2] = useState(false);
  const handleClose2 = () => setShow2(false);
  const handleShow2 = () => setShow2(true);

  const [show3, setShow3] = useState(false);
  const handleClose3 = () => setShow3(false);
  const handleShow3 = () => setShow3(true);


  const dispatch: AppDispatch = useDispatch()
  const userKYCList = useSelector((state: any) => state.userKYCList)

  const [isActive, setIsActive] = useState(false);
  const [singleDate, setSingleDate] = useState<Date | null>(new Date());
  const [popup, setPopup] = useState({ show: false, message: "" })
  const [perPage, setPerPage] = useState(10)
  const [paramState, setParamState] = useState(initialState);
  const [isAcceptLoadingId, setIsAcceptLoading] = useState("")
  const [isRejectLoadingId, setIsRejectLoading] = useState("")

  const { page, size, search, status } = paramState;
  const [value] = useDebounce(search, 300);


  useEffect(() => {
    let params = { page, search, size, status };
    dispatch(getUserKYCDetails(params));
  }, [value])


  useEffect(() => {
    const params = {
      page: 1, size: 10, search: "", status
    }
    const getMerchantKYCData = () => {
      dispatch(getUserKYCDetails(params))
    }
    getMerchantKYCData()
  }, [dispatch])

  const handlePageChange = async ({ selected }: any) => {
    let selectedPage = selected + 1;
    console.log("🚀 ~ handlePageChange ~ selectedPage:", status)
    setPerPage(10 * Number(selectedPage));
    let params = { page: selectedPage, search, size, status };
    await dispatch(getUserKYCDetails(params));
    setParamState({
      page: selectedPage,
      search,
      size,
      status
    })
  };

  const handleSearch = (e: any) => {
    const { name, value } = e.target;
    setParamState({
      page,
      search: value,
      size,
      status
    });
  };

  const handleStatus = (e: any) => {
    const { name, value } = e.target;
    let params = { page, search, size, status: value };
    dispatch(getUserKYCDetails(params));
    setParamState({
      page,
      search,
      size,
      status: value
    });
  };

  const handleChangeKYCStatus = (userId: any, status: any) => {
    setPopup({ show: false, message: "" })
    if (status === "Verified") {
      setIsAcceptLoading(userId)
    } else {
      setIsRejectLoading(userId)
    }
    identityService.changeUserKYCStatus({ userId, status }).then((res) => {
      setPopup({ show: true, message: res.message })
      dispatch(getUserKYCDetails(paramState))
      setIsAcceptLoading("")
      setIsRejectLoading("")
    }).catch((err) => {
      console.log(err)
    })
  }



  return (
    <>
      <div className="dbRight">
        <div className="mainHd">User KYC</div>
        <div className="whtBox">
          <div className="flexBox itemCenter justifyContentBetween">
            <div className="totalNumber">Total: {userKYCList?.data?.data?.length}</div>
            <ul className="flexBox filter">
              <li>
                <div className="filterSearch pr"><input onChange={handleSearch} type="text" placeholder="Search by Name, Trx ID"
                  className="filterInput" /> <span className="material-icons icon">search</span></div>
              </li>
              <li>
                <select onChange={handleStatus} className="filterSelect">
                  <option value="">All</option>
                  <option value="Pending">Pending</option>
                  <option value="Verified">Verified</option>
                  <option value="Incomplete">Incomplete</option>
                  <option value="Rejected">Rejected</option>
                </select>
              </li>
            </ul>
          </div>
          <div className="dbTable">
            <table>
              <tr>
                <th>Sr. No.</th>
                <th>User Name</th>
                <th>User ID</th>
                <th>Phone Number</th>
                <th>Email</th>
                <th>Country</th>
                <th>Status</th>
                <th>Action</th>
                <th>Details</th>
              </tr>
              {userKYCList?.loading ?
                [...Array(10)].map((data, i) => {
                  return (
                    <>
                      <tr key={i}>
                        <td><Skeleton /></td>
                        <td><Skeleton /></td>
                        <td><Skeleton /></td>
                        <td><Skeleton /></td>
                        <td><Skeleton /></td>
                        <td><Skeleton /></td>
                        <td><Skeleton /></td>
                        <td><Skeleton /></td>
                        <td><Skeleton /></td>
                      </tr>
                    </>

                  )
                })
                :
                userKYCList?.data?.data?.map((data: any, i: number) => {
                  return (
                    <tr key={i}>
                      <td>{i + 1 + (perPage - 10)}</td>
                      <td>{data.ew_full_name}</td>
                      <td>{data.ew_user_id}</td>
                      <td className="nowrap">{data.ew_phone_number}</td>
                      <td className="nowrap">{data.ew_email}</td>
                      <td className="nowrap" style={{ textTransform: "capitalize" }}>{data.ew_country}</td>
                      <td className="nowrap">{data.ew_eKYC_verification_status}</td>
                      <td>
                        <div className="flexBox nowrap">
                          <a className="viewLink mr10" onClick={() => handleChangeKYCStatus(data.ew_user_id, "Verified")}>{isAcceptLoadingId === data.ew_user_id ? "Loading..." : "Accept"}</a>
                          <a className="viewLink mr10" onClick={() => handleChangeKYCStatus(data.ew_user_id, "Rejected")}>{isRejectLoadingId === data.ew_user_id ? "Loading..." : "Reject"}</a>
                        </div>
                      </td>
                      <td>
                        <div className="flexBox nowrap">
                          <UserDetail data={data} />
                        </div>
                      </td>
                    </tr>
                  )
                })
              }

            </table>
          </div>
        </div>
        <div className="flexBox itemCenter justifyContentBetween">
          <ReactPaginate
            pageCount={Math.ceil(
              userKYCList?.data?.total / 10
            )}
            onPageChange={handlePageChange}
            containerClassName={"paging m-0 p-0 pt-3 d-flex"}
            activeClassName={"active"}
            previousLabel={"<"}
            nextLabel={">"}
          />
        </div>
      </div>
      <Modal show={show2}>
        <BlockUser onClose={handleClose2} />
      </Modal>
      <Modal show={show3}>
        <UnblockUser onClose={handleClose3} />
      </Modal>
      <Modal show={popup.show}><PopUp message={popup.message} onClose={() => setPopup({ show: false, message: "" })} /></Modal>
    </>

  )
}

export default UserManagement