import { Navigate, useLocation } from "react-router-dom"
import { getLocalAccessToken, getUserType } from './services/token.service';
import AdminHeader from './component/Admin/AdminHeader';
import { useState } from "react";
import AdminLeftMenu from "./component/Admin/AdminLeftMenu";


const AdminProtectedRoute = ({ children }: any) => {
    let location = useLocation();
    const accessToken = getLocalAccessToken()
    const userType = getUserType()
    const [isActive, setIsActive] = useState(false);

    const toggleMenu = () => {
        setIsActive(!isActive);
    };
    if (accessToken && userType === "Admin") {
        return (
            <>
                <div className={isActive ? "dbCon active" : "dbCon"}>
                    <AdminHeader onClick={toggleMenu} />
                    <AdminLeftMenu />
                    {children}
                </div>
            </>
        )
    }
    return <Navigate to="/adlg" state={{ from: location }} replace />

};

export default AdminProtectedRoute;